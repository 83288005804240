import React, { Component } from "react";

class GoogleMap extends Component {
  constructor() {
    super();
    this.state = {};
    this.googleMapRef = React.createRef();
  }
  googleMapRef = React.createRef();
  componentDidMount() {
    const GOOGLE_MAP_API_KEY = "AIzaSyAuD0MeK3b0Hux-jsTvua_hYKJTULs8S1U";
    const googleMapScript = document.createElement("script");
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=places`;
    window.document.body.appendChild(googleMapScript);
    googleMapScript.addEventListener("load", () => {
      this.googleMap = this.createGoogleMap();
      this.marker = this.createMarker();
    });
  }

  createGoogleMap = () =>
    new window.google.maps.Map(this.googleMapRef.current, {
      zoom: 16,
      center: {
        lat: 1.295347,
        lng: 103.85821
      },
      disableDefaultUI: true
    });

  createMarker = () =>
    new window.google.maps.Marker({
      position: { lat: 1.295347, lng: 103.85821 },
      map: this.googleMap
    });

  render() {
    return (
      <div
        id="google-map"
        ref={this.googleMapRef}
        style={{ width: "100%", height: "500px" }}
      />
    );
  }
}

export default GoogleMap;
