import React from "react";
import {
  MDBCarousel,
  MDBCard,
  MDBCardBody,
  MDBMask,
  MDBView,
  // MDBCol,
  MDBContainer,
  MDBCarouselInner,
  MDBCarouselItem,
} from "mdbreact";

// import Qapter_Presentation1 from "../assets/img/HomePageAssets/Qapter_presentation-12.jpg";
import NavBar from "./NavBar";
import "../assets/css/index.css";
import ContactUs from "./ContactUs";
import Footer from "./Footer";
import Content from "./Content";
import { S3_URL } from "../constants/constants";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      isWideEnough: false,
      intro: "",
      image_silder: [],
    };
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.setState({
      collapse: !this.state.collapse,
    });
  }

  componentDidMount() {
    fetch("https://audatexcms.herokuapp.com//api/v1/intro", {
      method: "GET",
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({ intro: result.description });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log("error");
        }
      );
    fetch("https://audatexcms.herokuapp.com//api/v1/get-images", {
      method: "GET",
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            image_silder: result,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log("error");
        }
      );
  }

  render() {
    const listItems = this.state.image_silder.map((link) => (
      <MDBCarouselInner>
        <MDBCarouselItem itemId={link.id}>
          <MDBView>
            <img
              className="d-block w-100"
              src={S3_URL + link.image_link}
              alt=""
            />
          </MDBView>
        </MDBCarouselItem>
      </MDBCarouselInner>
    ));

    return (
      <div>
        <NavBar />

        <div className="coverStyle">
          <MDBMask
            overlay="black-light"
            className="flex-center flex-column text-white text-center"
          >
            <div className="spacer" />
            <div className="spacer" />
            <br />
            <h1 className="h1Style">WHAT IS AUDATEX?</h1>
            <p className="pStyle">
              Audatex is a global leader in automotive repair estimation.{" "}
            </p>
            <br />
            <p className="pStyle">
              Using Audatex’s newest estimating platform, what repairers do is
              better understood by assessors & car owners alike.
            </p>
            <p className="pStyle">
              {" "}
              Audatex provides OEM times direct from the repair manual, allowing
              for more official guidelines on both mechanical labour as well as
              paintwork.
            </p>

            <hr />
            <MDBContainer size="sm">
              <div className="h-25 d-inline-block divCarousel">
                <MDBCarousel
                  activeItem={1}
                  length={5}
                  showControls={true}
                  showIndicators={true}
                  className="z-depth-1"
                  interval={3000}
                >
                  {listItems}
                </MDBCarousel>
              </div>
            </MDBContainer>
            <div className="spacer" />
          </MDBMask>
          <div className="spacer" />
        </div>

        <div className="w-auto p-3 divBackground">
          <MDBCard className="divBackground">
            <MDBCardBody>
              <hr />
              <Content />
              <hr />
            </MDBCardBody>
          </MDBCard>
        </div>
        <div className="divBackground">
          <ContactUs />

          <Footer />
        </div>
      </div>
    );
  }
}

export default Header;
