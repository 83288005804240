import React from "react";
import NavBar from "./NavBar";
import "../assets/css/index.css";
import Footer from "./Footer";
// import SoleraLogo from "../assets/img/HomePageAssets/Solera Logo.jpg";
// import Qapter_Presentation from "../assets/img/HomePageAssets/Qapter_presentation-1.jpg";
import CarEngine from "../assets/img/HomePageAssets/About us - Car Engine image.jpg";
import IPad from "../assets/img/HomePageAssets/About us - Ipad image.png";
import banner from "../assets/img/HomePageAssets/a_platform_for_customer_success.PNG";
import WhereWeHeading from "../assets/img/HomePageAssets/About us -where we are headed 2.JPG";
import { MDBRow, MDBCol, MDBContainer, MDBCard, MDBCardBody } from "mdbreact";
class AboutUsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      isWideEnough: false,
    };
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.setState({
      collapse: !this.state.collapse,
    });
  }
  render() {
    return (
      <div className="divBackground">
        <NavBar />
        <img className="img-fluid" src={banner} alt="" width="100%" />
        <div className="w-auto p-3 divBackground">
          <MDBContainer fluid>
            <h1 className="h1Style">About Audatex Singapore</h1>
            <MDBCard>
              <MDBCardBody>
                <MDBRow>
                  <MDBCol lg="5">
                    <img className="img-fluid" src={CarEngine} alt="" />
                  </MDBCol>
                  <MDBCol>
                    <p
                      style={{
                        color: "white",
                        fontFamily: "sofia-pro",
                        textAlign: "justify",
                        textJustify: "inter-word",
                      }}
                    >
                      Audatex was founded in 1966 by Swiss Re Corporation as a
                      service company providing solutions from first
                      notification to final settlement. Audatex soon became the
                      pioneer in simplified damage calculation and expanded
                      quickly to over 67 countries and more than 75,000
                      corporate clients. Audatex was acquired by its current
                      parent company, Solera Holdings Inc., in 2006. Audatex
                      Singapore was established in 2010 to promote a more
                      transparent and efficient motor repair claims process. By
                      connecting and building strong strategic partnerships with
                      prominent insurers & authorized repairers locally, Audatex
                      Singapore established itself as a trusted platform for
                      repair estimation.
                    </p>
                  </MDBCol>
                </MDBRow>
                <div className="spacer" />
                <MDBRow>
                  <MDBCol lg="7">
                    <p
                      style={{
                        color: "white",
                        fontFamily: "sofia-pro",
                        textAlign: "justify",
                        textJustify: "inter-word",
                      }}
                    >
                      Audatex Singapore today has strong partnerships with key
                      insurers as well as their partner authorized repairers and
                      independent assessor firms. Equipped with the latest
                      web-based estimation platform, Qapter Claims, repairers,
                      assessors & claims controllers are all able to create,
                      view & amend estimates with a level of ease and
                      accessibility like never. Claims estimation has been
                      transformed to a standardized, transparent & intuitive
                      process. By listening to our customers as well as our
                      partners, we are constantly evolving and improving our
                      services & practices to better facilitate an easier claims
                      estimation. By bringing every party involved in a claim’s
                      estimation process together, Audatex Singapore aims to
                      bring better understanding of what really goes on when a
                      car is repaired.
                    </p>
                  </MDBCol>
                  <MDBCol>
                    {" "}
                    <img className="img-fluid" src={IPad} alt="" />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol lg="5">
                    <img className="img-fluid" src={WhereWeHeading} alt="" />
                  </MDBCol>
                  <MDBCol>
                    <h2 className="h3StyleAboutUs">Where we are headed</h2>
                    <p
                      style={{
                        color: "white",
                        fontFamily: "sofia-pro",
                        textAlign: "justify",
                        textJustify: "inter-word",
                      }}
                    >
                      Self-driving cars. Connected vehicles. On-demand
                      ownership. Mobility services. Everything we know about
                      transportation is changing. We are the trusted connector
                      in the insurance and automotive ecosystems, which power
                      the future of mobility, electrification and sustainability
                      through data-driven insights, apps, and services.
                    </p>
                  </MDBCol>
                </MDBRow>
                <div className="spacer" />
              </MDBCardBody>
            </MDBCard>
          </MDBContainer>
        </div>

        <Footer />
      </div>
    );
  }
}

export default AboutUsPage;
