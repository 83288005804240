import React, {Component} from 'react'
import NavBar from "./NavBar";
import "../assets/css/index.css";
import Footer from "./Footer";
import { MDBCol, MDBContainer } from "mdbreact";
import { S3_URL } from '../constants/constants'

class SingleContentPage extends Component{
    constructor(props){
        super(props)
        this.state = {
            content : {}
        }
    }
    componentDidMount(){
        fetch("https://audatexcms.herokuapp.com/api/v1/content?content_id="+this.props.match.params.contentId,
        {
          method: 'GET',
        })
          .then(res => res.json())
          .then(
            (result) => {
              this.setState({
                content: result
              });
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              console.log('error')
            }
          )
    }

    render(){
        const content = this.state.content
            return (
              <div className="divBackground">
                <NavBar />
                <div className="spacer" />
                <MDBContainer className="divBackground">
                  <div className="spacer" />
        
                  <br />
                  <MDBCol>
            <h1 className="h1-style">{content.title}</h1>
                    <img className="mh-25 center-image" src={S3_URL+content.img} alt="" />
                    <MDBContainer>
                      <p className="pStyleContent">
                       {content.description}
                      </p>
                      <p style={{textAlign:'right',color:'white'}}>{content.created_at}</p>
                    </MDBContainer>
                  </MDBCol>
                  <hr />
                  <div className="spacer" />
                  {/* <img
                    className="mh-25 center-image"
                    src={Qapter_Presentation}
                    alt=""
                  /> */}
                      <div
      className="video"
      style={{
        position: "relative",
        paddingBottom: "56.25%" /* 16:9 */,
        paddingTop: 25,
        height: 0
      }}
    >
      {/* <iframe
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%"
        }}
        src={content.video}
        frameBorder="0"
      /> */}
    </div>
                </MDBContainer>
                <Footer />
              </div>
            );
          }
    }
export default SingleContentPage