import React from "react";
import "./App.css";
import { Route, Switch } from "react-router-dom";
import HomePage from "./components/HomePage";
import AboutUsPage from "./components/AboutUsPage";
import { BrowserRouter as Router } from "react-router-dom";
import SingleContentPage from "./components/SingleContentPage";
function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/about-us" component={AboutUsPage} />
        <Route exact path="/content/:contentId" render={props=>{
          return <SingleContentPage {...props} />
        }}
        />
      </Switch>
    </Router>
  );
}

export default App;
